// material-ui
import { Box, Container, Stack, Typography, Button, ButtonGroup, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';
import logoPng from 'assets/images/home/logo-png.png';
import AppleStore from 'assets/images/home/appstore.png';
import AppleStoreBeta from 'assets/images/home/applestoreBeta.png';
import GoogleMarket from 'assets/images/home/googlestore.png';
import QrIcon from 'assets/images/home/TDX-Strategies-QR.png';
import LinkedInIcon from 'assets/images/home/Linkedin-icon.svg';
import TwiterIcon from 'assets/images/home/Twitter-icon.svg';
import MediumIcon from 'assets/images/home/Medium-icon.svg';
// import YotubeIcon from 'assets/images/home/youtubeIcon.png';
// import InstaIcon from 'assets/images/home/InstagramIcon.png';
// import FaceBookIcon from 'assets/images/home/facebookIcon.png';
// import WhatSappIcon from 'assets/images/home/whatsappIcon.png';
import EmailIcon from 'assets/images/home/Email-icon.svg';
import TelegramIcon from 'assets/images/home/Telegram-icon.svg';
import { useI18n } from 'hooks/useI18n';

const Image = styled('img')({
    borderRadius: '6px',
    cursor: 'pointer',
    marginTop: 0
});
function AppIcon({ url, link }: any) {
    return (
        <Box target="_blank" component="a" href={link} sx={{ cursor: 'pointer', borderRadius: '10px' }}>
            <img height="40" style={{ borderRadius: '4px' }} alt="apple store" src={url} />
        </Box>
    );
}

function AppIconFooter({ url, link }: any) {
    return (
        <Box target="_blank" component="a" href={link} sx={{ cursor: 'pointer', borderRadius: '10px' }}>
            <img height="37"  style={{ borderRadius: '4px' }} alt="apple store" src={url} />
        </Box>
    );
}

function SmallAppIcon({ url, ...otherProps }: any) {
    return (
        <Box {...otherProps} component="a" sx={{ p: 0 }}>
            <Image
                sx={{ border: '2px solid white', borderRadius: '5px', p: '6px' }}
                height="38"
                alt="icon"
                src={url}
            />
        </Box>
    );
}

// function LinkeColumn({ category, urllist }: any) {
//     return (
//         <Stack spacing={2}>
//             <Typography variant="h2" sx={{ fontSize: { xs: '1rem', md: '1.36rem' }, color: 'white' }}>
//                 {category}
//             </Typography>
//             {urllist.map((item: any) => (
//                 <Button
//                     size="small"
//                     key={item.name}
//                     sx={{
//                         textAlign: 'left',
//                         fontSize: {
//                             xs: '.8rem',
//                             md: '1rem'
//                         },
//                         color: 'white',
//                         justifyContent: 'start',
//                         fontFamily: 'GothamBook',
//                         fontWeight: '400',
//                         lineHeight: '100%'
//                     }}
//                     component={Link}
//                     to={item.route ? item.route : '/'}
//                 >
//                     {item.name}
//                 </Button>
//             ))}
//         </Stack>
//     );
// }

const BtnLinkStyle = {
    color: 'white',
    height: '1rem',
    borderColor: 'white !important',
    fontFamily: 'GothamBook',
    fontWeight: 400,
    px: 1,
    '&:first-of-type': {
        pl: 0
    },
    fontSize: {
        xs: '0.6rem',
        sm: '0.8rem'
    }
};

const Footer = () => {
    // const productList = [
    //     {
    //         name: 'Yield Enhancement',
    //         route: '/products/yield-enhancement-strategy'
    //     },
    //     {
    //         name: 'Directional Exposure',
    //         route: '/products/directional-exposure-strategy'
    //     },
    //     {
    //         name: 'Tailored Strategies',
    //         route: '/products/tailor-strategy'
    //     }
    // ];

    const year = new Date().getFullYear();
    const {i18n} = useI18n();
    // const companyList = [
    //     {
    //         name: 'Purpose',
    //         route: '/company'
    //     },
    //     {
    //         name: 'Team',
    //         route: '/company#team'
    //     }
    // ];

    // const insightsList = [
    //     {
    //         name: 'Market Update',
    //         route: '/insights#market'
    //     },
    //     {
    //         name: 'Blog',
    //         route: '/insights#blog'
    //     }
    // ];

    // const goToTele = () => {
    //     window.open(`${process.env.REACT_APP_BASE_URL}/tdx/login`);
    // };
    return (
        <Box
            sx={{
                pt: 8,
                pb: 4,
                background: '#091561'
            }}
        >
            <Container sx={{ maxWidth: '1400px' }}>
                <Grid
                    item
                    container
                    xs={12}
                    sx={{
                        justifyContent: 'center',
                        display: {
                            xs: 'flex',
                            md: 'none'
                        }
                    }}
                >
                    <Box
                        sx={{
                            width: '130px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <img alt="" width="130" height="auto" src={logoPng} />

                        <Stack
                            spacing={3}
                            direction="column"
                            justifyContent="space-between"
                          
                            flexWrap="wrap"
                            my={2}
                            sx={{
                                display: {
                                    xs: 'flex',
                                    md: 'none'
                                } 
                            }}
                        >
                             <AppIcon
                                link="https://apps.apple.com/hk/app/tdx-anywhere/id1566072479?l=en-GB?l=en&platform=iphone"
                                url={AppleStore}
                            />
                            <AppIcon
                                link="https://play.google.com/store/apps/details?id=io.tdx.app"
                                url={GoogleMarket}
                            />
                           
                            <AppIcon
                                link="https://testflight.apple.com/join/a05kNs2Q"
                                url={AppleStoreBeta}
                            />
                        </Stack>
                    </Box>
                </Grid>
                <Grid
                    container
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'flex'
                        }
                    }}
                >
                    <Grid container item flexDirection="column" justifyContent="flex-end" xs={2}>
                        <img alt="" width="150" height="auto" src={logoPng} />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={10}
                        justifyContent="end"
                        sx={{
                            display: {
                                xs: 'none',
                                md: 'flex'
                            }
                        }}
                    >
                        <Box sx={{ alignSelf: 'end', marginTop: 6 }}>
                            <Stack flexDirection="row" justifyContent="space-between" sx={{ width: '260px' }}>
                                <Stack justifyContent="space-between" sx={{height:'120px'}}>
                                <AppIconFooter
                                        link="https://apps.apple.com/hk/app/tdx-anywhere/id1566072479?l=en-GB?l=en&platform=iphone"
                                        url={AppleStore}
                                    />
                                    <AppIconFooter
                                        link="https://play.google.com/store/apps/details?id=io.tdx.app"
                                        url={GoogleMarket}
                                    />
                                  
                                    <AppIconFooter
                                        link="https://testflight.apple.com/join/a05kNs2Q"
                                        url={AppleStoreBeta}
                                    />
                                </Stack>
                                <img alt="" width="120" height="120" style={{ opacity: 0.8 }} src={QrIcon} />
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{
                                    alignItems: 'center',
                                    marginTop: 3,
                                    width: '260px',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <SmallAppIcon href="mailto:support@tdx-strategies.com" url={EmailIcon} />
                                <SmallAppIcon
                                    target="_blank"
                                    href="https://t.me/+U6HvIIiUoEVpanqE"
                                    url={TelegramIcon}
                                />
                                <SmallAppIcon
                                    target="_blank"
                                    href="https://medium.com/tdx-strategies"
                                    url={MediumIcon}
                                />
                                <SmallAppIcon
                                    target="_blank"
                                    href="https://linkedin.com/company/tdx-strategies"
                                    url={LinkedInIcon}
                                />

                                <SmallAppIcon
                                    target="_blank"
                                    href="https://twitter.com/tdx_strategies"
                                    url={TwiterIcon}
                                />
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container
                    mt={4}
                    spacing={3}
                    sx={{
                        justifyContent: {
                            xs: 'center',
                            md: 'space-between'
                        }
                    }}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        md={8}
                        sx={{
                            alignItems: 'center',
                            justifyContent: {
                                xs: 'center',
                                md: 'start'
                            },
                            flexDirection:{
                                xs:'column',
                                md:'row'
                            }
                        }}
                    >
                        <ButtonGroup
                            orientation="horizontal"
                            variant="text"
                            sx={{
                                borderColor: 'white',
                                color: 'white',
                                display: {
                                    xs: 'none',
                                    md: 'block'
                                }
                            }}
                        >
                            <Button component={Link} sx={BtnLinkStyle} to="/company">
                            {i18n.t("ourfirm")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/platform">
                            {i18n.t("platforms")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/Insights">
                                 {i18n.t("insights")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/cookies-policy">
                                {i18n.t("cookiesPolicy")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/terms-of-service">
                                 {i18n.t("termOfService")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/privacy-policy">
                                 {i18n.t("privacyPolicy")}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup
                            orientation="horizontal"
                            variant="text"
                            sx={{
                                borderColor: 'white',
                                color: 'white',
                                marginBottom: '20px',
                                display: {
                                    xs: 'flex',
                                    md: 'none'
                                }
                            }}
                        >
                            <Button component={Link} sx={BtnLinkStyle} to="/company">
                            {i18n.t("ourfirm")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/platform">
                            {i18n.t("platforms")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/Insights">
                            {i18n.t("insights")}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup
                            orientation="horizontal"
                            variant="text"
                            sx={{
                                borderColor: 'white',
                                color: 'white',
                                display: {
                                    xs: 'flex',
                                    md: 'none'
                                }
                            }}
                        >
                            <Button component={Link} sx={BtnLinkStyle} to="/cookies-policy">
                            {i18n.t("cookiesPolicy")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/terms-of-service">
                            {i18n.t("termOfService")}
                            </Button>
                            <Button component={Link} sx={BtnLinkStyle} to="/privacy-policy">
                            {i18n.t("privacyPolicy")}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={4}
                        sx={{
                            alignItems: 'center',
                            justifyContent: {
                                xs: 'center',
                                md: 'end'
                            }
                        }}
                    >
                        <Typography
                            sx={{
                                color: 'white',
                                fontSize: {
                                    xs: '0.9rem',
                                    sm: '0.8rem'
                                },
                                lineHeight: '100%',
                                fontFamily: 'GothamBook',
                                fontWeight: 400
                            }}
                        >
                            Copyright © {year} TDX SG Pte. Ltd
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
